import React, { useState, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import Splash from "@layouts/splash"
import Plan from "@elements/Plan"
import PlanOption from "@elements/PlanOption"
import { useAuth0 } from "@utils/auth"
import { ProtectedRoute } from "../components/ProtectedRoute"
import Button from "@elements/Button"
import Surface from "@elements/Surface"

const permissions = `${process.env.GATSBY_AUTH0_NAMESPACE}permissions`

const Subscribe = ({
  data: { planOptions, site },
  pageContext: { language },
  location
}) => {
  const { user, logout, loading } = useAuth0()
  const [sortedPlans, setSortedPlans] = useState([])

  const plans = planOptions.nodes

  useEffect(() => {
    const filteredPlans = plans.find(plan => plan.locale === language)
    const sorted = [...filteredPlans.plans].sort(
      (a, b) => a.plan.price - b.plan.price
    )
    setSortedPlans(sorted)
  }, [plans])

  if (loading || !user) {
    return <p>Loading...</p>
  }

  const { sections, siteUrl } = site.siteMetadata

  const planDefaults = {
    redirectUrl: `${siteUrl}${sections.intro.base}`,
    cancelUrl: `${siteUrl}${sections.subscribe.base}`
  }

  return (
    <ProtectedRoute>
      <Splash>
        <>
          <Surface
            heading={"Select Your Plan"}
            lead={plans[0].lead ? `<p>${plans[0].lead}</p>` : null}
            fontSize="sm"
          >
            {sortedPlans.map(({ plan }) => {
              return (
                <PlanButton
                  key={`plan-${plan.alternative_id}`}
                  plan={plan}
                  planDefaults={planDefaults}
                  siteUrl={siteUrl}
                />
              )
            })}
            <p>{plans[0].disclaimer}</p>
            <Button
              type="button"
              text={"Logout"}
              secondary={true}
              onClick={() =>
                logout({ returnTo: process.env.GATSBY_AUTH0_CALLBACK })
              }
            />
          </Surface>
        </>
      </Splash>
    </ProtectedRoute>
  )
}

const PlanButton = ({ plan, planDefaults, siteUrl }) => {
  const { user } = useAuth0()
  const customer = user[permissions]

  if (!customer) return null

  const {
    name,
    price,
    description,
    trial_period,
    meta_data,
    alternative_id
  } = plan

  const planConfig = {
    ...planDefaults,
    name,
    price,
    description
  }

  if (meta_data !== null && meta_data !== undefined) {
    if (meta_data.redirectUrl) {
      planConfig.redirectUrl = `${siteUrl.replace(/\/$/, "")}${
        meta_data.redirectUrl
      }`
    }

    if (meta_data.label) {
      planConfig.label = meta_data.label
    }
    if (meta_data.specialOffer) {
      planConfig.specialOffer = meta_data.specialOffer
    }
    if (meta_data.hasLabel) {
      planConfig.hasLabel = meta_data.hasLabel
    }
    if (meta_data.colorLabel) {
      planConfig.colorLabel = meta_data.colorLabel
    }
  }

  return (
    <Plan
      planId={alternative_id}
      hasTrial={trial_period > 0}
      customerId={customer.customer.id}
      {...planConfig}
    />
  )
}

export const query = graphql`
  query SubscriptionsQuery {
    site {
      siteMetadata {
        sections {
          subscribe {
            title
            base
            slug
          }
          intro {
            title
            base
            slug
          }
        }
        siteUrl
      }
    }

    planOptions: allChargebeePlanOption {
      nodes {
        type
        label
        labelColor
        brand
        optionTitle
        id
        heading
        description
        locale
        lead
        disclaimer
        plans {
          id
          plan {
            alternative_id
            description
            name
            period
            period_unit
            price
            status
            trial_period
            trial_period_unit
            meta_data {
              label
              redirectUrl
              specialOffer
              hasLabel
              colorLabel
            }
          }
        }
      }
    }
  }
`

export default Subscribe
